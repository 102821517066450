import areaApi from '@/api/core/areas'

export const areas = {
    state: {
        areas: [],
    },
    mutations: {
        setAreas(state, areas){
            state.areas = areas;
        },
    },
    actions: {
        saveArea(context, params){
            return areaApi.saveArea(params.area);
        },
        deleteArea(context, params) {
            return areaApi.deleteArea(params.area);
        },
        async getAreas(context, params){
            if (context.state.areas && !params || (params &&!params.force)){
                return context.state.areas;
            }
            else if (context.state.areas !== "pending"){
                context.commit("setAreas", "pending");
                await areaApi.getAreas().then(areas => {
                    if (Array.isArray(areas.data)) {
                        let areasOptions = [];
                        areas.data.forEach(function(element) {
                            areasOptions.push({
                                id: element.area.id,
                                value: element.area.id,
                                label: element.area.name
                            });
                        });
                        context.commit("setAreas", areasOptions);
                    }
                });
            }
        }
    },
    getters: {
    }
}

import entityHelper from '@/api/entityHelper'
import axios from 'axios';

export default {
    findAreas(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/areas', filters, page, pageSize, orderField, orderMode);
    },
    getAreas(year) {
        let params = {};
        if(year && year != undefined){
            params.year = year;
        }
        return axios.get('/all-active-areas', params);
    },
    getArea(id) {
        return axios.get('/areas/' + id);
    },
    saveArea(area) {
        return entityHelper.saveEntity(area, '/areas');
    },
    deleteArea(area) {
        return entityHelper.deleteEntity(area, '/areas');
    },
    deleteAreas(idsList) {
        return axios.post('/areas/batch-delete', {
            list: idsList
        });
    }
}

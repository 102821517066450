export default {
    "1-Cash": "Efectivo",
    "2-Bank card": "Tarjeta",
    "3-Check": "Cheque",
    "4-Received": "Recibo",
    "5-At sight": "A la vista",
    "6-Transfer": "Transferencia",
    "7-Cash": "Caja",
    "9-Tarjeta Visa": "Tarjeta Visa",
    "79-PayPal": "PayPal",
    "215-Redsys": "Redsys",
    "500-PayU": "PayU",
    "800-Openpay": "Openpay"
}

import personApi from '../../api/core/person';
import userApi from '../../api/core/users';

import Vue from 'vue'

const initialState = () => ({
    images: {},
    currentUser: null,
    canLoadImages: true,
});

export const users = {
    state: initialState(),
    mutations: {
        resetUserInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
            state.currentUser = null;
        },
        //Store an image for an userId
        setUserImage(state, params){
            Vue.set(state.images, params.userId, params.image);
        },
        setCurrentUser(state, currentUser){
            state.currentUser = currentUser;
        },
        setCurrentUserMessages(state, messages){
            state.currentUser.listUnreadMessages = messages.list;
            state.currentUser.unreadMessages = messages.count;
        },
        setCurrentUserSurveys(state, surveys){
            state.currentUser.unreadSurveys = surveys;
        },
        setCurrentPerson(state, currentPerson){
            state.currentPerson = currentPerson;
        },
        markMessageAsRead(state, messageId){
            // console.log("markMessageAsRead", state, Array.isArray(state.currentUser.listUnreadMessages));
            if(Array.isArray(state.currentUser.listUnreadMessages)){
                let messageIndex = state.currentUser.listUnreadMessages.findIndex(x => x.id == messageId);
                // console.log("messageIndex", messageIndex);
                state.currentUser.listUnreadMessages.splice(messageIndex, 1);
                state.currentUser.unreadMessages -= 1;
            }
        },
        addMessageAsUnread(state, message){
            // console.log("addMessageAsUnread", state, message);
            if(!Array.isArray(state.currentUser.listUnreadMessages)){
                state.currentUser.listUnreadMessages = [];
            }
            // console.log("listUnreadMessages", state.currentUser.listUnreadMessages);
            if(state.currentUser.listUnreadMessages.findIndex(lm => lm.id == message.id) < 0){
                state.currentUser.listUnreadMessages.push(message);
                state.currentUser.unreadMessages = state.currentUser.listUnreadMessages.length;
            }
        },
        setCanLoadImages(state, canLoadImages){
            state.canLoadImages = canLoadImages;
        }
    },
    actions: {
        async getUserImage(context, params) {
            // console.log("getUserImage: ",params.userId," - context.state.images[params.userId]: ",context.state.images[params.userId]);
            if (typeof (context.state.images[params.userId]) !== 'undefined') {
                //Returns the image (if it has one) or "pending" if a petition has been made to get the image of this user (and a response hasn't been received yet)
                return context.state.images[params.userId];
            }
            if (params.userId && context.state.canLoadImages && (context.state.images[params.userId] !== "pending" && context.state.images[params.userId] !== "notExist")) {
                //Get the image of this user if we didn't make a petition to get it yet
                Vue.set(context.state.images, params.userId, "pending");
                await personApi.getProfilePicture(params.userId).then((response) => {
                    if(!response){  response = "notExist";  }
                    //console.log("resposta getUserImage2 user:",params.userId," - response:", response);
                    Vue.set(context.state.images, params.userId, response);
                }).catch(function (e) {
                    Vue.set(context.state.images, params.userId, "undefined");
                });
            }else{
                //console.log("resposta getUserImage user:",params.userId," notEXIST");
            }
        },
        async currentUser({state,commit}, params){
            //console.log("store current user: ",state.currentUser);
            if (state.currentUser!=null && state.currentUser!='pending'){
                return state.currentUser
            }else if(state.currentUser!='pending'){
                commit('setCurrentUser', 'pending');
                await userApi.getCurrentUser().then((response) => {
                    if (response !== undefined && response.data !== undefined && response.data.user.username !== undefined) {
                        // console.log("respostaCurrent user: ", response.data);
                        let currentUser = response.data.user;
                        currentUser.unreadMessages = response.data.unread_messages;
                        currentUser.listUnreadMessages = response.data.list_unread_messages;
                        currentUser.unreadSurveys = response.data.pendingSurveys;
                        commit('setCurrentUser', currentUser);
                    }
                    else{
                        commit('setCurrentUser', null);
                        state.dispatch('currentUser', params);
                        console.log("!!!! RESPOSTA INESPERADA AL OBTENIR USUARI !!!! : ", response);
                    }
                }).catch(error => {
                    state.currentUser = null;
                });
            }
        },
    },

    getters: {
        userImage: (state) => (src) => {
            return state.images[src]
        },
        getCurrentUser: (state) => {
            return state.currentUser
        },
        getCurrentPerson: state => {
            return state.currentUser.person;
        },
        getListUnreadMessages: (state) => {
            return state.currentUser.listUnreadMessages;
        },
        getCanLoadImages: (state) => {
            return state.canLoadImages;
        }
    }
}

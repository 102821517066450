import enrolmentsApi from '@/api/core/enrollments'
const initialState = () => ({
  student: null,
  tutorsList: [],
  tutorsData: [],
  enrolment: null,
  step: false,
  yearFilter: null,
  visited: {
    'AddEnrolmentStudent': 0,
    'AddEnrolmentTutors': 0,
    'AddEnrolmentSubjects': 0,
    'AddEnrolmentSummary': 0,
  }
});
export const enrolments = {
    state: initialState(),
    mutations: {
        setEnrolmentStudent(state, student) {
            state.student = student;
        },
        setEnrolmentTutorsList(state, tutorsList){
            state.tutorsList = tutorsList;
        },
        setEnrolmentTutorsData(state, tutorsData){
            state.tutorsData = tutorsData;
        },
        setEnrolmentData(state, enrolment){
            state.enrolment = enrolment;
        },
        setEnrolmentStep(state, step){
            state.step = step;
        },
        setEnrolmentYearFilter(state, yearFilter){
            state.yearFilter = yearFilter;
        },
        setEnrolmentVisited(state, object){
            state.visited[object.step] = object.visited;
        },
        resetEnrolmentVisited(state){
            state.visited = {
                'AddEnrolmentStudent': 0,
                'AddEnrolmentTutors': 0,
                'AddEnrolmentSubjects': 0,
                'AddEnrolmentSummary': 0,
            };
        },
       resetCenterInfo (state) {
         const newState = initialState();
         Object.keys(newState).forEach(key => {
           state[key] = newState[key]
         });
      },
    },
    getters: {
        getEnrolmentStudent: state =>{
            return state.student;
        },
        getEnrolmentTutorsList: state =>{
            return state.tutorsList;
        },
        getEnrolmentTutorsData: state =>{
            return state.tutorsData;
        },
        getEnrolmentData: state => {
            return state.enrolment;
        },
        getEnrolmentStep: state => {
            return state.step;
        },
        getEnrolmentYearFilter: state => {
            return state.yearFilter;
        },
        getEnrolmentVisited: state => {
            return state.visited;
        }
    },
    actions :{
        /**
         * Creates a PDF for the enrolmentId based on the templateId.
         * @returns {Promise<void>}
         * @param context
         * @param params
         */
        async printEnrolment(context, params){
            await enrolmentsApi.getEnrolmentPDF(params.enrolmentId, params.templateId, params.schemaName, params.personId).then((response) => {
                //Get the filename
                const contentDisposition = response.headers['content-disposition'];
                let regexResult = contentDisposition.match("filename[^;=\\n]*=((['\"]).*?\\2|[^;\\n]*)");

                //Remove double quotes
                let filename = regexResult[1].replace(/^"(.+(?="$))"$/, '$1');

                //Create the blob
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                context.commit("setLoading", false);
            })
        }
    }
}


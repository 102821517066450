// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
import router from './router';
import i18n from './translations';
import VeeValidate, {Validator} from 'vee-validate';
import ca from 'vee-validate/dist/locale/ca';
import es from 'vee-validate/dist/locale/es';
import store from './store/store';
import errorHandler from './errorControler/errorControler';
import $ from 'jquery';
import 'bootstrap';
import axios from 'axios';
import authApi from '@/api/core/auth';
import cutFunctions from '@/filters/cutFunctions';
import { ToastPlugin } from 'bootstrap-vue';
import { TooltipPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue';
import { CollapsePlugin } from 'bootstrap-vue'
import validationMessagesCa from 'vee-validate/dist/locale/ca';
import validationMessagesEs from 'vee-validate/dist/locale/es';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueLocalStorage from 'vue-localstorage';
import scripts from './assets/js/scripts';
import VueGraph from 'vue-graph';
import VueClip from 'vue-clip';
import Tooltip from 'vue-directive-tooltip';
import './assets/css/tooltip.css';
import "vue-swatches/dist/vue-swatches.min.css";
import { abilitiesPlugin } from '@casl/vue';
import ability from './config/ability';
import { Can } from '@casl/vue';
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCryptojs from 'vue-cryptojs';
import VueEvents from 'vue-events';
import UserApi from "./api/core/users";
import GlobalMixin from './mixins/global';

// ==== Lodash ============================= //
import VueLodash from 'vue-lodash';

import capitalize from 'lodash/capitalize';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isNil from 'lodash/isNil';
import orderBy from 'lodash/orderBy';
import startCase from 'lodash/startCase';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import has from 'lodash/has';
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use(VueLodash, { lodash: { capitalize, clone, cloneDeep, debounce, filter, find, findIndex, isEmpty, isFinite, isNil, orderBy, startCase, uniqueId, isEqual, get, has } });
// ========================================= //

Vue.use(VueGraph);
Vue.use(VueClip);
Vue.mixin(GlobalMixin)

import VueTour from 'vue-tour'
Vue.use(VueTour)

//bootstrap
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);
Vue.use(ModalPlugin);
Vue.use(CollapsePlugin);
Vue.use(Tooltip);
Vue.use(VueEvents);
Vue.use(CKEditor);
Vue.use(VueReCaptcha,
  {siteKey:'6LfHq1gUAAAAAOHSRnWYX5y2sqjYu5w0sAd01wID',
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true
    }
});

Vue.config.productionTip = false;

//let baseUrl = "http://" + document.domain + ":" + process.env.API_PORT;
let codiClientIni = document.domain.indexOf("://") + 1;
let codiClientFi = document.domain.indexOf(".");
let baseUrl = process.env.API_PROTOCOL + "://" + document.domain.substring(codiClientIni, codiClientFi)+ "." + process.env.API_DOMAIN + ":"  + process.env.API_PORT;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = baseUrl;
// mirar wike  Fulxe authentication front.
axios.interceptors.response.use(axiosFilters.onFulfilledResponse, axiosFilters.errorInterceptorResponse);
axios.interceptors.request.use(axiosFilters.onFulfilledRequest);
let lang = localStorage.language;
if (typeof lang === "undefined" || lang == "undefined" || lang == null || lang == "") { //Check if there is a language stored in the local storage
    /*UserApi.getLang().then(response => {
        lang = response.data.lang.toLowerCase();
        localStorage.setItem("language", lang);
        i18n.locale = lang;
    });*/
} else {
    if (lang !== i18n.locale) {
        i18n.locale = lang;
    }
}


// Vee-Validate
const config = {
    // locale: lang,
    errorBagName: 'vErrors',
    fieldsBagName: 'IeducaFormFields',
    i18nRootKey: 'validations', // customize the root path for validation messages.
    i18n,
    dictionary: {
        ca: validationMessagesCa,
        es: validationMessagesEs,
    }
};
Vue.use(VeeValidate, config);

Vue.use(abilitiesPlugin, ability);
Vue.component('Can', Can);

window.ability = ability; //added for debugging purposes and should be removed for production build
/* Permissions end */

Vue.use(VueCryptojs);

Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
});
Vue.filter('cutName', function (value, maxlength, initials) {
    let result = cutFunctions.cutName(value, maxlength, initials);
    return result;

});
Vue.filter('cutCompleteName', function (value, surname1, surname2, maxLength, nameLast = false) {
    return cutFunctions.cutCompleteName(value, surname1, surname2, maxLength, nameLast);
});
Vue.filter('cutSurname', function (value, surname2, maxLength) {
    return cutFunctions.cutSurnames(value, surname2, maxLength);
});

new Vue({
    el: '#app',
    router,
    i18n,
    store,
    components: {App},
    template: '<App/>',
}
);

import axiosFilters from "./filters/axiosFilters";

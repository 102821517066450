<template>
    <span :data-dropmic-direction="direction" :class="['dropmic ', css]" mouseover="open()" @mouseleave="close()" :data-dropmic="id" role="navigation" >
        <span class="button-box-ie" data-dropmic-btn>
            <div v-if="isCenter" class="centerLogo" :style="centerImage" ></div>
            <div v-else style="display:inline-block;" >
                <user-image :key="userId" :userId="userId" :name="name" :surname="surname" :extraClass="extraClass"></user-image>
            </div>
            <alert-icon v-if="icon" :icon="icon" :number="number"></alert-icon>
            <div class="center-label" id="center-label">
              <span v-if="button_titel" class="dropdown-header-ie" id="top-name-dropdown" v-html="button_titel"></span>
              <span v-if="data && data.length>0" class="h-100 pl-3"><i class="arrow"></i></span>
            </div>
        </span>
        <div id="top-menu-dropdown" class="dropmic-menu" @mouseover="open()" @mouseleave="close()" aria-hidden="true">
            <center id="top-user-image" v-if="!isCenter"><user-image :userId="userId" :name="name"  :surname="surname"  :imgSrc="imgSrc"  extraClass="large" ></user-image></center>
            <div v-if="header" class="dropmic-menu__custom" v-html="header"></div>
            <ul class="dropmic-menu__list" role="menu">
                <li v-if="data && data.length>0" class="dropmic-menu__listItem" role="menuitem" v-for="(item,index) in centersData" >
                    {{imgCenter(item,index)}}
                    <div class="dropmic-menu__listContent" @click="click(item.centerCode)" >
                        <div v-if="item.img" class="centerLogo" :style="item.img"></div>
                        <span v-html="item.name"></span>
                        <span v-html="item.label"></span>
                       </div>
                </li>
                <li v-if="!data || data.length==0" class="dropmic-menu__listItem" role="menuitem">
                    <span class="dropmic-menu__listContent">{{$t('senseDades')}}</span>
                </li>
            </ul>
            <div @click="footerClick" v-if="footer" class="dropmic-menu__custom dropmic-footer-ie" v-html="footer" ></div>
        </div>
    </span>
</template>
<script>
    import Dropmic from 'dropmic';
    import AlertIcon from '../../components/AlertIcon';
    import UserImage from '../../components/UserImage';
    import center from '../../api/core/center.js';
    import { mapState } from 'vuex';
    export default {
        name: "dropdown-box",
        components: {
            AlertIcon,
            UserImage
        },
        props: {
            button_titel: { type: String, required: false },
            icon: { type: String, required: false },
            number: { required: false },
            header: { type: String, required: false },
            footer: { type: String, required: false },
            id: { type: String, required: false, default: "1" },
            direction: { type: String , required: false, default: "" },
            name: { type: String, required: false },
            surname: { type: String, required: false },
            isCenter:{ type: Boolean, required: false },
            data: { type: Array, required: false },
            centerCode: { type: String, required: false },
            imgSrc: { type: String, required: false },
            userId: { type: [ String, Number ], required: false},
            css: { type: String, required: false},
        },
        data() {
            return{
                sourceImg: null,
                drop: null,
                idOpen: false,
                extraClass: 'small floatLeft',
                centerImage: null,
                centersData: this.data,
                currentCenterCode: this.centerCode,
                currentImgSrc : this.imgSrc,
            }
        },
        watch: {
            imgSrc: function(newVal){
                this.currentImgSrc = newVal;
            },
            centerCode: function(newVal){
                this.currentCenterCode = newVal;
                this.centerImage = "background-image: url('" + this.currentImgSrc + "')";

                // TODO: Això es crida aquí i al mounted! Mirar de no repetir
                /* center.centerImage(this.currentCenterCode, this.currentImgSrc).then((response) => {
                    if(response!="" && response!="undefined"){
                        this.centerImage = "background-image: url('"+response+"')";
                    }
                }); */
            },
            data:  function(){
                let _self = this;

                _self.centersData = [];
                _self.data.forEach((item, i) => {
                    _self.centersData[i] = item;
                    _self.centersData[i]['img'] = "background-image: url('" + _self.currentImgSrc + "')";
                    /* center.centerImage(item.centerCode, item.logo).then((response) => {
                        if(response!="" && response!="undefined"){
                            this.centersData[i]['img'] = "background-image: url('"+response+"')";
                        }
                    }); */
                });
            }
        },
        mounted() {
            this.drop = new Dropmic(document.querySelector('[data-dropmic="' + this.id + '"]'));
            this.centerImage = "background-image: url('" + this.currentImgSrc + "')";
            /* center.centerImage(this.currentCenterCode, this.currentImgSrc).then((response) => {
                if(response!="" && response!="undefined"){
                    this.centerImage = "background-image: url('"+response+"')";
                }
            }); */
        },
        computed: {
            ...mapState({
                client: state => state.client,
            }),
        },
        methods: {
            click: function (value) {
                this.$emit('click', {id: value});
            },
            footerClick:function(){
                this.$emit('footerClick');
            },
            close() {
                this.isOpen =false;
                this.sleep(200).then(()=>{
                    if(!this.isOpen){
                        this.drop.close();
                    }
                });
                ;
            },
            open() {
                this.isOpen =true;
                this.drop.open();
            },
            sleep(time){
                return new Promise((resolve) => setTimeout(resolve, time));
            },
            imgCenter(item,index) {
                let logoImage64 = "";

                if (item.logoBase64 != "" && item.logoBase64 != null && typeof item.logoBase64 != 'undefined') {
                    logoImage64 = "data:image/png;base64," + item.logoBase64;
                } 
                if(logoImage64 == "" && this.client.logo){
                    logoImage64 = this.client.logo;
                }

                item['img'] = "background-image: url('" + (logoImage64 != "" ? '' + logoImage64 : "") + "')";
                //console.log("----------> codi: "+item.centerCode+" --- img: "+item.logo);
                /* center.centerImage(item.centerCode, item.logo).then((response) => {
                    if(response!="" && response!="undefined"){
                        item['img'] = "background-image: url('"+response+"')";
                    }
                }); */
            }
        },
    }
</script>
<style scoped lang="scss">
    @import "../../assets/css/variables.scss";

    .top-logo{
        width:90px;
        height:35px;
    }    
    .centerLogo{
        margin-right: 0.5rem;
        width: 150px;
        height: 48px;
        background-position: center center;
        background-size: contain; 
        background-repeat: no-repeat;
        display: inline-flex;
    }
    .center-label{
       display: inline-block;
    }
    .global-center div#center-label {
      background-color:$warning;
      color:$white;
      padding: 0 0.5rem 0 0.5rem ;
      border-radius: 1rem 1rem 1rem 1rem;
    }
    .topCenter #center-label{ margin-top: -1.1rem;  }

</style>

import axios from 'axios';
import GroupApi from '@/api/core/groups';
import ClassesApi from '@/api/core/classes';

const initialState = () => ({
    classesOptionClicked: null,
    myGroups: {},
});
export const classes = {
    state: initialState(),
    mutations: {
        setClassesOptionClicked(state, option) {
            state.classesOptionClicked = option;
        },
        resetCenterInfo(state) {
            state.classesOptionClicked = null
            state.myGroups = {};
        },
        setMySubjectClasses(state, option){
            state.myGroups = option
        },
    },
    actions: {
        async getMySubjectClasses(context, params){
            // console.log("getMySubjectClasses al store! state: ",context.state.myGroups, params)
            if(!context.state.myGroups || Object.keys(context.state.myGroups).length == 0 || params.force) {
                await ClassesApi.getClassesSubject().then((response) => {
                    context.commit("setMySubjectClasses", response.data.classes);
                });
            }else {
                return context.state.myGroups;
            }
        },
    },
    getters: {
        getClassesToolsClicked: state => {
            return state.classesOptionClicked;
        },
        getMySubjectClasses: state => {
            return state.myGroups
        },
    }
};

<template>
    <div class="h-100">
        <div class="row h-100">
            <div class="col-sm-12 login-div">
                <card-security ref="cardSecurity" :extra-login-class="'pt-0'">
                    <div class="row" v-if="loginPartActive">
                        <div class="change-domain col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-right linkStyle pb-4" @click="loginPartActive=false">
                            <span>{{$t('changeDomain')}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" id="loginCardForm">
                            <welcome id="welcome-login"></welcome>
                            <div class="row-form login-box">
                                <div class="spiner" v-if="handlingTokensUrl">
                                    <spinner :status="true" :color="getThemeColor()" :size="60" :depth="10" :speed="1"></spinner>
                                </div>
                                <div class="col" v-else>
                                    <input-ie id="username" name="username" @keyup="cleanError($event)" :label="placeUsername" type="text"
                                                v-model="username"
                                                v-validate="'required'"
                                                :viewEdit="true"/>
                                    <input-ie id="password" name="password" :label="placePsw" type="password" v-model="password"
                                                icon='fa fa-eye-slash' :viewEdit="true" class="pt-2"
                                                v-validate="'required|min:1'" ref='valuePwd' @keyup="cleanError($event)" :showAfterIcon="true"/>
                                    <div class="subindex-ie">
                                        <a class="recover-password" @click="recoverPassword">{{ $t('user.recoverPassword')}}?</a>
                                    </div>

                                    <div class="text-center div-btn-ie ">
                                        <large-button name="btn_submit" @click="login()" type="submit">
                                            {{$t("access") }}
                                        </large-button>
                                        <recover-help></recover-help>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="show2FA">

                        </div>
                    </div>
                    <div id="else" class="row" v-else>
                        <div class="col-12 no-padding">
                            <welcome></welcome>
                            <div class="row-form login-box">
                                <div class="spiner" v-if="availableDomains.length <1">
                                    <spinner :status="true" :color="getThemeColor()" :size="60" :depth="10" :speed="1"></spinner>
                                </div>
                                <div v-else>
                <!--                  <select-ie name="school" ref="school" :view-edit="true" :options="availableDomains"-->
                <!--                             :labelText="placeDomains" :labe="placeDomains"/>-->
                                    <label fro="school">{{$t('domain')}}</label>
                                    <vue-bootstrap-typeahead
                                        ref="school"
                                        name="school"
                                        :data="suggestions"
                                        v-model="autocompleteValue"
                                        @hit="activeButton=true"
                                    />
                                    <div class="text-center div-btn-ie-chose-center ">
                                        <large-button :class="[{disabled:!activeButton}]" name="btn_submit" @click.prevent="changeUrl" type="submit">
                                            {{$t("access") }}
                                        </large-button>
                                        <recover-help></recover-help>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card-security>
            </div>
        </div>
        <modal-privacity></modal-privacity>
    </div>
</template>

<script>

    import domains from '@/api/core/center_lists';
    import ModalPrivacity from './security/ModalPrivacity';
    import LargeButton from '@/components/buttons/LargeButton';
    import cardSecurity from './security/CardSecurity';
    import InputIe from '@/components/InputIe';
    import RecoverHelp from './security/RecoverHelp';
    import Welcome from './security/Welcome';
    import SelectIe from '@/components/dropdown/SelectIe';
    import clientsApi from '@/api/core/clientsApi';
    import Spinner from 'vue-spinner-component/src/Spinner.vue';
    import securityHelper from "@/utils/securityHelper";
    import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
    import { mapMutations } from "vuex";

    export default {
        name: "login",
        components: {
            ModalPrivacity,
            LargeButton,
            cardSecurity,
            InputIe,
            RecoverHelp,
            Welcome,
            SelectIe,
            Spinner,
            VueBootstrapTypeahead,
        },
        data() {
            return {
                username: null,
                password: null,
                availableDomains: [],
                psw: null,
                placeUsername: this.$i18n.t("username"),
                placePsw: this.$i18n.t("password"),
                message: null,
                placeDomains: this.$i18n.t("domain"),
                loginPartActive: this.matchedDomain,
                autocompleteValue: undefined, // posat per el v-model pero per algun motiu no ho omple
                suggestions:[],
                activeButton:false,
                handlingTokensUrl: true,
                show2FA: false
            //    centers: this.$store.dispatch('getClients'),
            }
        },
        computed: {
            authError() {
                return this.$store.state.auth.auth_error;
            },
            matchedDomain() {
                //    return 1;
                return this.availableDomains.filter(e => e.value + "." + process.env.FRONTEND_DOMAIN === document.domain).length == 1;
            }
        },
        methods: {
            ...mapMutations([
                'setAuthError',
                'setError'
            ]),
            clearLoginError() {
                this.setAuthError(false);
                this.setError(null);
                this.$refs.cardSecurity.setErrors("", "");
            },
            login() {
                this.clearLoginError();
                this.$validator.validate().then(async valid => {
                    if (valid) {
                        this.$store.commit('deleteMenus');
                        this.$store.dispatch('resetCenterInfo');
                        this.$store.commit('resetUserInfo');
                        this.$store.commit("setExpandedMenuId", null);
                        await securityHelper.login(this.username, this.password, this.$store).then(response => {
                            this.$store.dispatch('getParameterAmbitOrAreaName');
                        })
                    }
                });

            },
            changeUrl() {
                // console.log(this.$refs.school.inputValue);
                let school =  this.$refs.school.inputValue.split("[");
                // console.log(school);
                let code = school[1].replace("]","");
                window.location = process.env.FRONTEND_PROTOCOL + "://" + code + "." + process.env.FRONTEND_DOMAIN + ":" + process.env.FRONTEND_PORT;
            },
            cleanError(event) {
                // console.log('celaerErrror',event);
                this.$store.commit('clearError');
                if(event.code=== "Enter") this.login();
            },
            async getAnonimusToken() {
                await this.$store.dispatch('anonimusToken').then(response => {
                    // this.getClients();
                });

            },
            getClients() {
                clientsApi.getClients().then((response) => {
                    this.availableDomains = response.data;
                    this.loginPartActive = this.part =this.availableDomains.filter(e => e.value + "." + process.env.FRONTEND_DOMAIN === document.domain).length == 1
                    this.availableDomains.forEach(domain=>{
                        this.suggestions.push(domain.label+' ['+domain.value+']');
                    });
                });
            },
            recoverPassword() {
                this.$router.push('/recover/password');
            },
            loginWithTokens() {
                let url = new URL(window.location.href);
                let access_token = url.searchParams.get("access_token");
                let refresh_token = url.searchParams.get("refresh_token");
                let expires_in = url.searchParams.get("expires_in");
                let urlParts = url.hostname.split(".");
                let clientCode = urlParts[0];

                if (access_token != null && refresh_token != null && expires_in != null) {
                    let self = this;
                        securityHelper.loginWithTokens(access_token, refresh_token, expires_in, clientCode).then(response => {
                            self.handlingTokensUrl = false;
                        }).catch(error => {
                            self.handlingTokensUrl = false;
                        });
                }
                else{
                    this.handlingTokensUrl = false;
                }
            }
        },
        created() {
            this.$root.$on('iconEvent-password', function (args) {
                let p = document.getElementById('password');
                let eye = document.getElementById('icon-password');
                if (p.getAttribute('type') == 'password') {
                    p.setAttribute("type", "text");
                    eye.setAttribute("class", "fa fa-eye-slash");
                } else {
                    p.setAttribute("type", "password");
                    eye.setAttribute("class", "fa fa-eye");
                }
            });
            this.message = this.$route.params.success;
        },
        mounted() {
            if (this.message != null) {
                this.$refs.cardSecurity.setErrors(this.$i18n.t(this.message), "success");
            }
            //eleminem coses de storge que poden canviar per usuari
            this.$store.commit('setGroupsCoursesStages', []);// segons el teu rol veus uns groups o uns altres.
            //Get from the url the variables: access_token, refresh_token, expires_in
            this.loginWithTokens();
        },
        beforeMount() {
            this.$store.commit('deleteMenus');
            this.getClients();
          //  this.getAnonimusToken();
        }

    }
</script>

<style scoped lang="scss">
    @import "../assets/css/variables";
    html, body {
        height: 100vh;
    }

    .login-box {
        padding-top: 60px;
        margin-right: 5px;
    }

    #error-box div.alert {
        border-bottom-left-radius: 1rem;
        border-top-left-radius: 0px;
        border-bottom-right-radius: 1rem;
        border-top-right-radius: 0px;
    }

    .footer {
        background-color: transparent;
        color: white;
        position: absolute;
        bottom: 2rem;
        font-size: 0.9em;
        left: 0px !important;
    }

    .modal-privacity {
        max-height: 30rem;
        max-width: 28rem;
    }

    .modal-body {
        overflow-y: auto;
    }

    .subindex-ie {
        font-size: 12px;
    }

    .div-btn-ie-chose-center {
        padding-top: 82px;
    }


    .no-padding {
        padding: 0px;
    }

    .selected-tag .form-control {
        visibility: hidden;
    }

    .spiner {
        margin-left: 40%;
    }
    .change-domain{
        color: $leftMenuLinkOpen;
    }

    #else.row {
        padding-left: 45px;
        padding-right: 45px
    }

    #loginCardForm{
        padding: 0 3rem;
        @media (max-width: 768px){
            padding: 0 1rem;
        }
        @media (max-width: 480px){
            padding: 0;
        }
    }

    @media (min-width: 1500px){
        #welcome-login{
            margin-left: -27px;
        }
        .btn-block{
            width:98%;
        }
        .container { width: inherit !important; }
    }

    @media (max-width: 576px){
        .change-domain{
            right: -0.3rem;
        }
    }

    @media (max-width: 420px){
        .login-box {
            padding-top: 60px;
            width: 100%;
            margin-left: 0px;
        }
        .subindex-ie{
            a{
                font-size: 0.8rem;
            }
        }
        .change-domain{
            right: 0;
        }
        .div-btn-ie {
            padding: 0px !important;
            margin-top: 85px;
        }
    }
    @media (max-width: 380px){
        .login-box {
            padding-top: 35px;
        }
    }
    @media (max-width: 340px){
        .div-btn-ie {
            margin-top: 0px;
        }
        .login-box {
            padding-top: 10px;
        }
        .subindex-ie {
            margin-top: 25px;
        }
    }
</style>
<style lang="scss">
.login-div{
    align-self: center;
    @media (max-width: 480px){
        align-self: auto;
        padding: 0 !important;
        height: 100%;
    }
}
</style>

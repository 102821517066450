import Vue from 'vue';

const initialState = () => ({
    checkedIds: {},
});

export const vuetable = {
    state: initialState(),
    mutations: {
        setCheckedIds(state, checkedIds){
            state.checkedIds = checkedIds;
        },
        setCheckedId(state, data){
            Vue.set(state.checkedIds, data.id, data.checked);
        }
    },
    getters: {
        getCheckedIds: state => {
            return state.checkedIds;
        }
    },
    actions: {
     }
}


import entityHelper from '@/api/entityHelper'
import axios from 'axios';

export default {
    getList(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/groups', filters, page, pageSize, orderField, orderMode);
    },
    getGroupsTypesLevelCourse(){
      return axios.get('/groups-types-level-course');
    },
    get(id) {
        return axios.get('/groups/' + id);
    },
    getOfficialGroupByGroupId(id) {
        return axios.get('/official-group/' + id);
    },
    save(group) {
        return entityHelper.saveEntity(group, '/groups');
    },
    createGroup(name) {
        let group = {
            'code': null,
            'name': name,
            'stageId': null,
            'courseStageId': null,
            'members': []
        };
        return entityHelper.saveEntity(group, '/groups');
    },
    update(group) {
        return entityHelper.saveEntity(group, '/groups/' + group.id);
    },
    delete(group) {
        return entityHelper.deleteEntity(group, '/groups');
    },
    batchDelete(idsList) {
        return axios.post('/groups/batch-delete', {
            list: idsList
        });
    },
    getGroupsAndPersons(query, filters, limit, selectedList, extraParams, cancelToken, year = null) {
        let params = {};
        let queryText = query || "";
        let queryFilters = filters || [];
        let limitValue = limit || 0;
        let selectedListArray = selectedList || [];
        let extraParamObject = extraParams || null;
        let config = {};

        if (queryText != "") {
            params['q'] = queryText;
        }

        if (queryFilters.length > 0) {
            params['filters'] = queryFilters;
        }

        if (limitValue > 0) {
            params['limit'] = limitValue;
        }

        if (selectedListArray.length > 0) {
            params['selected'] = selectedListArray;
        }

        if (extraParamObject != null) {
            params['extraParams'] = extraParamObject;
        }
        if (year != null) {
            params['year'] = year;
        }

        if (cancelToken != null) {
            config['cancelToken'] = cancelToken;
        }

        return axios.post('/groups/search', params, config);
    },
    changeVisibility(group) {
        return axios.post('/groups/change-visible', {
            id: group.id
        });
    },
    setVisibility(visible, ids){
        return axios.post('/groups/setVisibility', {
            ids: ids,
            visible: visible
        });
    },
    getStudents(id, over18 = 'all') {
        return axios.get('/group-members/' + id + '/student/' + over18);
    },
    getParents(id) {
        return axios.get('/group-members/' + id + '/parent/all');
    },
    getTeachers(id, filterYear= null) {
        let params = { 'id': id };
        if(filterYear && filterYear != undefined){
            params.year = filterYear;
        }
        return axios.get('/group-members/' + id + '/teacher/all', { params });
        //return axios.get('/group-members', params);
    },
    getMembers(id, over18 = 'all') {
        return axios.get('/group-members/' + id + '/all/' + over18);
    },
    getTimetableGroups(courseStageId, stageId, teacherId, groupBySubjects, groupCourseStageId) {
        let courseStageIdValue = courseStageId || 0;
        let stageIdValue = stageId || 0;
        let teacherIdValue = teacherId || 0;
        let groupBySubjectsValue = groupBySubjects || false;
        let groupCourseStageIdValue = groupCourseStageId || 0;
        let config = {
            params: {
                'courseStageId': courseStageIdValue,
                'stageId': stageIdValue,
                'teacherId': teacherIdValue,
                'groupBySubjects': groupBySubjectsValue,
                'groupCourseStageId': groupCourseStageIdValue
            }
        };
        // let params = {
        //     'courseStageId': courseStageIdValue,
        //     'stageId': stageIdValue,
        //     'teacherId': teacherIdValue,
        //     'groupBySubjects': groupBySubjectsValue
        // }
        //
        //
        // const response = fetch('http://client1.ieduca.com:8000/timetable-groups', {
        //     method: 'GET',
        //     credentials: 'include',
        //     params: params, // string or object
        //     headers: {
        //         'Authorization': 'Bearer '+sessionStorage.getItem('token'),
        //     }
        // });
        // console.log(response);
        // return response;
        var today = new Date();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        //console.log("fent petició a les", time);
        return axios.get('/timetable-groups', config);
    },
    getMyGroups(){
        return axios.get('/timetable-mygroups', {});
    },
    getOfficialGroups(stageId, courseStageId, teacherId, onlyTutor = false, filterYear = null) {
        let stageIdValue = stageId || 0;
        let courseStageIdValue = courseStageId || 0;
        let config = {
            params: {
                'stageId': stageIdValue,
                'courseStageId': courseStageIdValue,
                'teacherId': teacherId,
                'onlyTutor': onlyTutor,
            }
        };
        if(filterYear){ config.params.year = filterYear; }

        return axios.get('/official-groups', config);
    },
    getStudentGroups(studentId){
        return axios.get('/student-groups/'+studentId);
    },
    getOfficialAndTempGroups(courseStageId){
        return axios.get('/official-and-temp-groups/'+courseStageId);
    },
    getTempGroups(){
        return axios.get('/temp-groups');
    },
    getAllowedGroups(studentId) {
        let params = {
            studentId: studentId
        };
        return axios.get('/allowed-groups/', {params});
    }
}

import $ from 'jquery';
import store from '../../store/store';
import Vue from 'vue';

/* Changes the paginator size based on the screen width */
$(window).resize(function() {               
    let windowWidth = document.documentElement.clientWidth;
    if (windowWidth <= 991 && $('.mini-sidebar').length == 0 || windowWidth <= 575){
        store.commit('paginator', {paginator: 'mini'});
    } else {
        store.commit('paginator', {paginator: 'full'});
    }
});

//box-shadow: 0 0 15px $tableTdTextColor;

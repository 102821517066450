export default  {
    getImageApp(pathImage){
        if(pathImage!='' && pathImage!='undefined'){
            let hostname = window.location.hostname;
            let images = require.context('../assets/image', true); 
            return images('./' + pathImage);
        }
        return null;
    },
    getImageClient(pathImage){
        if(pathImage!='' && pathImage!='undefined'){
            let hostname = window.location.hostname;
            let domain = hostname.substring(0, hostname.indexOf('.'));
            let images = require.context('../assets/image/clients', true); 
            return images('./'+domain + '/' + pathImage);
        }
        return null;
    },
    dataUrlToFile(dataUrl, fileName) {
        let fileInfo = dataUrl.split(',');
        let mimeType = fileInfo[0].match(/:(.*?);/)[1];
        let base64ToStr = atob(fileInfo[1]);
        let len = base64ToStr.length;
        let strToOctet = new Uint8Array(len);

        while (len >= 0) {
            strToOctet[len-1] = base64ToStr.charCodeAt(len-1);
            len -= 1;
        }

        return new File([strToOctet], fileName, { type: mimeType })
    }
}



<template>
    <!-- <div> -->
        <span :class="cssLength">{{counterLength}}</span>
    <!-- </div> -->
</template>

<script>
    export default {
        name: "CounterInput",
        props: {
            length: {type: Number, default: 0, required: true},
            max: {type: Number, default: 0, required: false},
            min: {type: Number, default: 0, required: false}
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        computed: {
            counterLength(){
                let html = "";
                if(this.max) {
                    html = this.formatData(this.max);
                } else if(this.min) {
                    html = this.formatData(this.min);
                } else {
                    html = this.$tc("setMaxMinLength"); 
                }
                return html;
            },
            cssLength(){
                let css = "";
                if((this.max > 0 && this.length > this.max) || (this.min > 0 && this.length < this.min) || this.counterLength == this.$tc("setMaxMinLength")){
                    css = "text-danger";
                } 
                return css;
            },
        },
        methods: {
            formatData(value, separador = "/"){
                let html = this.length;
                html += separador;
                html += value;
                return html;
            }
        },
    }
</script>

<style scoped lang="scss">
    @import "@/assets/css/variables.scss";
    span{
        color: #5a6872;
    }
</style>
import { AbilityBuilder } from '@casl/ability'
import Users from "@/api/core/users";

export default AbilityBuilder.define(can => {
    /*Users.getPermissions().then((response) => {
        let permissions = response.data;
        for(let i=0; i<permissions.lenght; i++){
            let perm = permissions[i];
            can(perm.actions, perm.subject);
        }
    });*/
})
<template>
    <button v-bind:id="id" class="btn btn-block" @click="click">
        <slot></slot>
    </button>
</template>

<script>
    export default {
        name: 'button-large',
        props: ['id'],
        methods: {
            click: function (e) {
                this.$emit('click', e);
            }
        }
    }
</script>

/**
 * Automatically imports all the modules and exports as a single module object
 */

/*
const requireModule = require.context('.', false,  /\.store\.js$/);
const modules = {};

requireModule.keys().forEach(filename => {
    // create the module name from fileName
    // remove the store.js extension and capitalize
    const moduleName = filename
        .replace(/(\.\/|\.store\.js)/g, '')
        .replace(/^\w/, c => c.toUpperCase())

    modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default modules;


modules['core'] = [];
modules['core']['auth'] = './store/auth'
modules['core']['areas'] = './store/core/areas'
modules['core']['spaces'] = './store/core/spaces'
modules['core']['importer'] = './store/core/importer'
modules['core']['users'] = './store/core/users'
modules['core']['security'] = './store/security'
modules['core']['academicStructure'] = './store/core/academicstructure'
modules['core']['currentCenter'] = './store/core/currentCenter'
modules['core']['persons'] = './store/core/persons'
modules['core']['enrolments'] = './store/core/enrolments'
modules['core']['menu'] = './store/core/menu'
modules['core']['subjects'] = './store/core/subjects'
modules['timetable'] = [];
modules['timetable']['slots'] = './store/timetable/slots'
*/

import {auth} from './auth';
import {areas} from './core/areas';
import {spaces} from './core/spaces';
import {importer} from './core/importer';
import {users} from './core/users';
import {security} from './security';
import {currentCenter} from './core/currentCenter';
import {persons} from './core/persons';
import {enrolments} from './core/enrolments';
import {menu} from './core/menu';
import {timetable} from './timetable/timetable';
import {subjects} from './core/subjects';
import {academicStructure} from './core/academicstructure';
import {attendance} from './core/attendance';
import {fieldType} from "./Form/fieldType";
import {grades} from './grades/grades';
import {fileManager} from './filemanager/fileManager';
import {message} from "./message/message";
import {classes} from "./core/classes";
import {common} from "./core/common";
import {tutors} from "./core/tutors";
import {calendar} from "./calendar/calendar";
import {program} from "./core/program";
import {parameters} from "./core/parameters";
import {template} from "./template/template";
import {vuetable} from "./core/vuetable";

const modules = {
    auth: auth,
    areas: areas,
    spaces: spaces,
    importer: importer,
    security: security,
    users: users,
    academicStructure: academicStructure,
    currentCenter: currentCenter,
    persons: persons,
    enrolments: enrolments,
    menu: menu,
    timetable: timetable,
    subjects: subjects,
    attendance: attendance,
    grades: grades,
    fieldType: fieldType,
    fileManager: fileManager,
    message: message,
    classes: classes,
    common: common,
    tutors: tutors,
    calendar: calendar,
    program: program,
    parameters: parameters,
    template: template,
    vuetable: vuetable
};

export default modules;

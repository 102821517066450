export default {
    "1-Cash": "Efectiu",
    "2-Bank card": "Targeta",
    "3-Check": "Xec",
    "4-Received": "Rebut",
    "5-At sight": "A la vista",
    "6-Transfer": "Transferència",
    "7-Cash": "Caixa",
    "9-Tarjeta Visa": "Targeta Visa",
    "79-PayPal": "PayPal",
    "215-Redsys": "Redsys",
    "500-PayU": "PayU",
    "800-Openpay": "Openpay"
}

import TutorsApi from '@/api/core/tutors'
import Vue from 'vue';

const initialState = () => ({
    tutorsList: [],
});

export const tutors = {
    state: initialState(),
    mutations: {
        setTutorsList(state, option){
            state.tutorsList = option;
        },
        resetCenterInfo(state) {
            tutorsList: []
        },
    },
    getters: {
        getTutorsList: state => {
            return state.tutorsList;
        }
    },
    actions: {
     }
}


import timetableApi from '@/api/timetable/timetable';
import i18n from '@/translations';
import {RRule} from "rrule";

export const timetable = {
    state: {
        slots: null,
        categories: null,
        workingDays: null,
        nonWorkingDays: null,
        yearDates: {start: null, end: null},
        previousSchemas: [
            slots => null,
            categories => null,
            workingDays => null,
            nonWorkingDays => null,
            yearDates => null,
        ],
        lastEventClicked: null
    },
    mutations: {
        setSlots(state, slots){
            state.slots = slots;
        },
        setCategories(state, categories){
            state.categories = categories;
        },
        setWorkingDays(state, workingDays){
            // console.log("setWorkingDays");
            state.workingDays = workingDays;
        },
        setNonWorkingDays(state, nonWorkingDays){
            state.nonWorkingDays = nonWorkingDays;
        },
        resetCenterInfo(state) {
            state.slots = null;
            state.categories = null;
            state.workingDays =  null;
            state.nonWorkingDays  = null;
        },
        setYearDates(state, yearDates){
            state.yearDates = yearDates;
        },
        setLastEventClicked(state, lastEventClicked){
            state.lastEventClicked = lastEventClicked;
        }
    },
    actions: {
        async getSlots(context, params){
            let previousSchema = context.state.previousSchemas["slots"];
            if(context.state.slots && (!previousSchema && !params.schemaName || previousSchema && params.schemaName && previousSchema === params.schemaName)){
                return context.state.slots;
            }
            else{
                await timetableApi.getTimetableSlots(params.schemaName).then((response) => {
                    let slots = response.data.data;
                    if (slots) {
                        slots.forEach(function (e, index) {
                            slots[index].label = e.hourIni + ' - ' + e.hourEnd;
                        });
                    }
                    context.state.previousSchemas["slots"] = params.schemaName;
                    context.state.slots = slots;
                    return context.state.slots;
                });
            }
        },
        async getCategories(context, params){
            let previousSchema = context.state.previousSchemas["categories"];
            if (context.state.categories && (!previousSchema && !params.schemaName || previousSchema && params.schemaName && previousSchema === params.schemaName)){
                return context.state.categories;
            }
            else{
                await timetableApi.getCategories(params.onlyActives, params.schemaName).then(response => {
                    context.state.previousSchemas["categories"] = params.schemaName;
                    context.state.categories = response.data.map(c => {
                        let category = c;
                        if(c.keyName){
                            category.name = i18n.tc(c.keyName);
                        }
                        return category;
                    });
                    return context.state.categories;
                })
            }
        },
        async getYearDates(context, params){
            let previousSchema = context.state.previousSchemas["yearDates"];
            if (context.state.yearDates.start && context.state.yearDates.end && (!previousSchema && !params.schemaName || previousSchema && params.schemaName && previousSchema === params.schemaName)){
                return context.state.yearDates;
            }
            else{
                await timetableApi.getYearDates(params.yearCode).then(response => {
                    context.state.previousSchemas["yearDates"] = params.schemaName;
                    context.state.yearDates.start = response.data.startDate;
                    context.state.yearDates.end = response.data.endDate;
                    return context.state.yearDates;
                })
            }
        },
        async loadWorkingAndNonWorkingDays(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }

            if(!force && context.state.workingDays !== "pending" && context.state.workingDays && context.state.workingDays.length > 0){
                return context.state.workingDays;
            } else if(!context.state.workingDays && context.state.workingDays !== "pending") {
                context.commit("setWorkingDays", "pending");
                let weekDays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
                await timetableApi.getWorkingDays().then(response => {
                    let days = response.data.data;
                    let workingDays = [];
                    days.forEach(function(e){
                        workingDays.push(weekDays[e]); //Working days
                    });
                    context.commit("setWorkingDays", workingDays);
                    //Non working days is the difference between all the days and the working days
                    let nonWorkingDays = weekDays.filter(x => !context.state.workingDays.includes(x)).map(x => (x.weekday+1 < 7 ? x.weekday+1 : 0));
                    context.commit("setNonWorkingDays", nonWorkingDays);
                }).catch(e => {
                    context.commit("setWorkingDays", []);
                });
            }
        },
    },
    getters: {
        getSlots: state => {
            return state.slots;
        },
        getCategories: state =>{
            return state.categories;
        },
        getWorkingDays: state =>{
            return state.workingDays;
        },
        getNonWorkingDays: state =>{
            return state.nonWorkingDays;
        },
        getYearDates: state =>{
            return state.yearDates;
        },
        getLastEventClicked: state =>{
            return state.lastEventClicked;
        }
    }
}

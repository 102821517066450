import roles_es from '../translations/locales/es/roles.es';
import roles_ca from '../translations/locales/ca/roles.ca';
import lodash from 'lodash';

export default  {
    translateIfPrefix(name, translator){
        if (name != undefined && name !== "") {
            if (name.startsWith("groupRole.") || name.startsWith("groupType.")) {
                return translator.t(name).trim();
            } else {
                return name.trim();
            }
        }
        return "";
    },
    findConcept(table,concept){
      if(lodash.isNil(concept)) return [];
      let lang = localStorage.language;
      let nameTable = table+'_'+lang;
      let translationList;
      concept = lodash.deburr(concept.toLowerCase());
      switch (nameTable.toLowerCase()) {
        case('roles_es'):{
           translationList = roles_es;
           break;
        }
        case('roles_ca'):{
          translationList = roles_ca;
          break;
        }
      }
      let found=[];
      lodash.forEach(translationList,function(elem,key){
          elem = elem.toLowerCase();
          elem = lodash.deburr(elem);
         if(elem.includes(concept)){
           found.push(key);
         }
      });
      return lodash.sortedUniq(found);
    },
    getNavigatorLocale() {
        let lang = "ca";

        if (!/^ca\b/.test(navigator.language)) {
            lang = "es";
        }

        return lang;
    }
}

import axios from 'axios';

export default {
    changeCenter(schemaName){
        const parameters = new FormData();
        parameters.append('change_center', schemaName);
        return axios.post('/switchCenter',parameters);
        //return axios.post('/switchCenter?'+parameters);
    },
    getCenters(){
      return axios.get('/centerDropDown');
    }
}


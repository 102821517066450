import entityHelper from '@/api/entityHelper'
import axios from 'axios';

export default {

  findRoles(){
    // return entityHelper.get("/spaces?page="  + page + "&perPage=" + pageSize + "&sort=" + orderField + "|" + orderMode)
    // return entityHelper.findEntity("/roles", [])
    return axios.get("/rolesList");
  },
  findRolesWithChildren(){
    return axios.get('/rolesListGlobal');
  },
  rolesList(){
    return entityHelper.findEntity("/rolesList", []);
  },
  getRole(roleId){
    return entityHelper.get('/roles/get/' + roleId)
  },
  saveRole(role) {
    return entityHelper.saveEntity(role, '/roles');
  },
  deleteRole(role) {
    return entityHelper.deleteEntity(role, '/roles');
  },
  findMembers(roleId, filters, page, pageSize, orderField, orderMode){
    filters.fields = { role : roleId}
    // console.log("Buscant membres per grup " + JSON.stringify(filters));
    return entityHelper.findEntity("/roles/members", filters, page, pageSize, orderField, orderMode)
  },
  addMember(role, user){
    return axios.post('/roles/member', {roleId: role.id, userId: user.id});
  },
  addPersonsToRole(roleId, personsIds){
    return axios.post('/roles/addMembersToRole', {roleId: roleId, personsIds: personsIds});
  },
  deleteMember(role, users){
    return axios.post('/roles/deletemember', { roleId: role.id, users : users});
    // return axios.delete('/roles/member/' + role.id + '/' + user.id);
  },
  addMemberToRoles(personId, rolesIds){
    return axios.post('/roles/addMemberToRoles', {personId: personId, rolesIds: rolesIds});
  },

  deleteMemberToRoles(personId, rolesIds){
    return axios.post('/roles/deleteMemberToRoles', {personId: personId, rolesIds: rolesIds});
  },
  duplicate(roleId,name){
    let parameters = new FormData();
    parameters.append('roleId', roleId);
    parameters.append('name',name);
    return axios.post('roles/duplicate',parameters);
  },
  getRolesOfType(roleId){
    return axios.get('roles/rolesOfType?typeOfId='+roleId);
  },
  localToGlobal(roleId){
    return axios.post('roles/localToGlobal/'+roleId);
  },
 globalToLocal(roleId,centerId){
   let parameters = new FormData();
   parameters.append('centerId', centerId);
    return axios.post('roles/globalToLocal/'+roleId,parameters);
  },
  getRoleExceptSelf(roleId, centerId){
    let params = {'roleId': roleId, 'centerId': centerId};
    // parameters.append('roleId',roleId);
    // if(centerId !== undefined){
    //   parameters.append('centerId',centerId);
    // }
    // console.log("getRoleExceptSelf", roleId, centerId, parameters);
    // console.log("getRoleExceptSelf", roleId, centerId, params);
    return axios.get('/rolesDropDown', {params});
  },
  copyMemberToRole(roleId,usersIds){
    let parameters = new FormData();
     parameters.append('roleId',roleId);
     parameters.append('personsId',usersIds);
     return axios.post('/roles/addPersons',parameters);
  },
  moveMemberToRole(originId,destinationId,usersIds){
    let parameters = new FormData();
    parameters.append('originId',originId);
    parameters.append('destinationId',destinationId);
    parameters.append('personsId',usersIds);

    return axios.post('/roles/move',parameters);
  },

    setPersonRoles(personId, roles){
      let parameters = {roles: roles};
      return axios.post('/roles/setPersonRoles/'+personId, {parameters});
    }

}

import axios from 'axios';

//al profile i la persona van a controllers diferents ja que els permisos són diferents
// no es el mateix gestionar-te a tu mateix (password, tags...) que gestionar a una persona

export default {
  getClients() {
    return   axios.get('/clients');
  }
}

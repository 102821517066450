export default {
    "importadorrestoresession": "Tens una sessió d'importació sense finalitzar, vols continuar-la?",
    "import.nav.file" : "Importar",
    "import.nav.matching" : "Relacionar camps",
    "import.nav.preview" : "Previsualitzar",
    "import.nav.result" : "Resultat",

    "import.entity.room" : "espai|espais",
    "import.entity.subject" : "matèria|matèries",
    "import.entity.academicstructure" : "estructura acadèmica",
    "import.entity.academicstructureBasic" : "estudis bàsics",
    "import.entity.academicstructureAcademic" : "estructura acadèmica",
    "import.entity.student" : "alumne|alumnat",
    "import.entity.teacher" : "profesor|profesorat",
    "import.entity.contact" : "contacte|contactes",
    "import.entity.user" : "usuari|usuaris",
    "import.entity.group" : "grup|grups",
    "import.entity.enrolment" : "matrícula|matrícules",
    "import.entity.area" : "departament|departaments",
    "import.entity.commentGradesDefault" : "comentaris predefinits",
    "import.entity.program" : "programació",

    'import.breadcrumb.import' : 'Importador',
    'import.breadcrumb.selecttype' : 'Selecció tipus importació',
    'import.breadcrumb.preview' : 'Previsualitzar resultats',
    "import.index.restoresession" :  "Hi ha una sessió anterior de importació sense finalitzar del",
    "import.index.continuesession" :  "Vols continuar-la?",
    "import.existing": "Importació existent",

    'import.choosefile.import': 'Importador',
    'import.choosefile.hint': 'Arrosega els teus arxius aqui o navega',
    'import.choosefile.continue': 'Continuar',
    'import.choosefile.download': 'Descarregar',
    'import.choosefile.download_samples': 'Plantilla',
    'import.choosefile.download_actual_data': 'Dades actuals',
    'import.choosefile.parseError': "Error al processar l'arxiu",
    'import.choosefile.importFile': "Seleccionar arxiu",
    'import.choosefile.drag.hint': "Arrosega l'arxiu a qualsevol punt de la pantalla",

    'import.matching.headingOnFirstRow': 'No importar la primera fila',
    'import.matching.field': "camp",
    'import.matching.notMapped': "sense relacionar",
    'import.matching.matching': "Relacionar camps",
    'import.matching.requiredField': "Camp obligatori",
    'import.matching.optionalField': "Camp opcional",
    'import.matching.selectField': "Selecciona camp",

    'import.preview.field': "camp",
    'import.preview.all': 'Tots',
    'import.preview.error': 'Error',
    'import.preview.warnings': 'Actualitzats',
    'import.preview.ok': 'Importats',
    'import.preview.import': 'Importar',
    'import.preview.export': 'Exportar registres amb error',
    'import.preview.importDone': 'Importació finalitzada',
    'import.preview.importAdded': " registres afegits",
    'import.preview.importUpdated': " registres modificats",
    'import.preview.importError': " registres amb errors",
    'import.preview.added': 'Importables',
    'import.preview.updated': 'Actualitzables',
    'import.firstRowValues': "Valors a la primera fila",

    'import.subtotals.importing': 'Important',
    'import.subtotals.added': 'Afegits',
    'import.subtotals.updated': 'Actualitzats',
    'import.subtotals.error': 'Amb errors',
    'import.subtotals.youHave': 'Tens',
    'import.subtotals.pendingAlerts': 'No tens alertes pendents | Tens {count} alerta pendent | Tens {count} alertes pendents',

    'importablefield.code' : 'Codi',
    'importablefield.name' : 'Nom',
    'importablefield.area' : 'Area',

    'importablefield.parent': 'Mòdul',
    'importablefield.study': 'Estudi',
    'importablefield.color': 'Color',

    "importablefield.stage_code" : "Codi nivell",
    "importablefield.stage_name" : "Nom nivell",
    "importablefield.stage_ieduca_id" : "Id nivell",
    "importablefield.study_code" : "Codi estudi",
    "importablefield.study_name" : "Mom estudi",
    "importablefield.study_ieduca_id" : "Id estudi",
    "importablefield.level_code" : "Codi curs",
    "importablefield.level_name" : "Nom curs",
    "importablefield.level_ieduca_id" : "Id curs",
    "importablefield.group_code" : "Codi grup",
    "importablefield.group_name" : "Nom grup",
    "importablefield.isBookable": "Reservable",
    "importablefield.isMeetingRoom": "Reunió",
    "importablefield.isClassroom": "Classe",
    "import.entity.esferastudent" : "alumnat Esfera",
    "importablefield.group": "Grup classe",
    "importablefield.subjects": "Matèries",
    "importablefield.stage": "Nivell",
    "importablefield.commerce_code": "Codi línia de negoci",
    "importablefield.commerce_name": "Nom línia de negoci",
    "import.entity.competence": "Competències",
    "import.entity.planning": "Planificació",
    "importablefield.personFields": "Camps personalitzats",
    "importablefield.code": "Codi",
    "importablefield.name": "Nom",
    "importablefield.bookable": "Reservable",
    "importablefield.meetingroom": "Sala de reunions",
    "importablefield.area": "Departament",
    "importablefield.stage_code": "Codi nivell",
    // "importablefield.stage_name": "Nom nivell", --> already defined.
    "importablefield.stage_ieduca_id": "ID nivell",
    "importablefield.study_code": "Codi estudi",
    "importablefield.study_name": "Nom estudi",
    "importablefield.study_ieduca_id": "ID estudi",
    "importablefield.commerce_code": "Codi línia de negoci",
    // "importablefield.commerce_name": "Nom línia de negoci", --> already defined.
    "importablefield.course_code": "Codi curs",
    "importablefield.course_name": "Nom curs",
    "importablefield.group_code": "Codi grup",
    "importablefield.group_name": "Nom grup",
    "importablefield.childName": "Nom",
    "importablefield.childSurname1": "Cognom 1",
    "importablefield.childSurname2": "Cognom 2",
    "importablefield.childDni": "Document d'identitat",
    "importablefield.enrolmentCodeGroupCourseStage": "Codi grup matriculat",
    "importablefield.enrolmentNum": "Numero de matricula",
    "importablefield.fatherId": "ID iEduca pare",  
    "importablefield.fatherName": "Nom pare",
    "importablefield.fatherSurname1": "Cognom 1 pare",
    "importablefield.fatherSurname2": "Cognom 2 pare",
    "importablefield.fatherDni": "Document d'identitat pare",
    "importablefield.fatherNif": "NIF pare",
    "importablefield.motherId": "ID iEduca mare",    
    "importablefield.motherName": "Nom mare",
    "importablefield.motherSurname1": "Cognom 1 mare",
    "importablefield.motherSurname2": "Cognom 2 mare",
    "importablefield.motherDni": "Document d'identitat mare",
    "importablefield.motherNif": "NIF mare",
    "importablefield.childBirthday": "Data naixement",
    "importablefield.childPlaceBirth": "Lloc naixement",
    "importablefield.childNationality": "Nacionalitat",
    "importablefield.childLang": "Idioma principal",
    "importablefield.childNia": "NIA",
    "importablefield.childNif": "NIF",
    "importablefield.childNumSS": "NSS",
    "importablefield.childNGender": "Sexe (H/D)",
    "importablefield.childStreet": "Adreça",
    "importablefield.childTown": "Poblacio",
    "importablefield.childCountry": "Pais",
    "importablefield.childZip": "Codi postal",
    "importablefield.childId": "ID iEduca",
    "importablefield.childGender": "Sexe (H/D)",
    "importablefield.contactChildEmail": "Email",
    "importablefield.contactChildEmail1": "Email 1",
    "importablefield.contactChildEmail1Info": "Info email 1",
    "importablefield.contactChildEmail2": "Email 2",
    "importablefield.contactChildEmail2Info": "Info email 2",
    "importablefield.contactChildEmail3": "Email 3",
    "importablefield.contactChildEmail3Info": "Info email 3",
    "importablefield.contactChildPhone1": "Tel 1",
    "importablefield.contactChildPhone1Info": "Info tel 1",
    "importablefield.contactChildPhone2": "Tel 2",
    "importablefield.contactChildPhone2Info": "Info tel 2",
    "importablefield.contactChildPhone3": "Tel 3",
    "importablefield.contactChildPhone3Info": "Info tel 3",
    "importablefield.contactFatherEmail": "Email pare",
    "importablefield.contactFatherEmailInfo": "Info email pare",
    "importablefield.contactFatherPhone": "Tel pare",
    "importablefield.contactFatherPhoneInfo": "Info tel pare",
    "importablefield.contactMotherEmail": "Email mare",
    "importablefield.contactMotherEmailInfo": "Info email mare",
    "importablefield.contactMotherPhone": "Tel mare",
    "importablefield.contactMotherPhoneInfo": "Info tel mare",
    "importablefield.childProvince": "Provincia",
    "importablefield.teacherId": "ID iEduca",
    "importablefield.teacherName": "Nom",
    "importablefield.teacherSurname1": "Cognom 1",
    "importablefield.teacherSurname2": "Cognom 2",
    "importablefield.teacherDni": "Document d'identitat",
    "importablefield.teacherGender": "Sexe (H/D)",
    "importablefield.teacherBirthday": "Data de naixament",
    "importablefield.teacherStreet": "Direcció",
    "importablefield.teacherTown": "Població",
    "importablefield.teacherZip": "Codi postal",
    "importablefield.contactTeacherPhone": "Telèfon",
    "importablefield.contactTeacherEmail": "Correu electrònic",
    "importablefield.teacherRole": "Rol",
    "importablefield.teacherGroup": "Grup tutoria",
    "importablefield.contactPersonId": "ID iEduca",
    "importablefield.contactPersonDni": "Document d'identitat",
    "importablefield.contactPersonNia": "NIA",
    "importablefield.contactPersonNif": "NIF",
    "importablefield.contactValue": "Contacte",
    "importablefield.childTags": "Etiquetes",
    "importablefield.contactInfo": "Info",
    "importablefield.parent": "Mòdul",
    "importablefield.study": "Estudi",
    "importablefield.color": "Color",
    "importablefield.id": "ID iEduca",
    "importablefield.surname1": "Cognom 1",
    "importablefield.surname2": "Cognom 2",
    "importablefield.birthday": "Data de naixement",
    "importablefield.placeBirth": "Lloc de naixement",
    "importablefield.nationality": "Nacionalitat",
    "importablefield.nia": "NIA",
    "importablefield.dni": "Document d'identitat",
    "importablefield.nif": "NIF",
    "importablefield.numSS": "Num. Seguretat Social",
    "importablefield.gender": "Sexe (H/D)",
    "importablefield.zip": "Codi postal",
    "importablefield.town": "Població",
    "importablefield.country": "Pais",
    "importablefield.user.username": "Usuari",
    "importablefield.enrolments.completeName": "Grups matriculats",
    "importablefield.personGroups.group.name": "Rols",
    "importablefield.tagPerson.tag.name": "Etiquetes",
    "importablefield.userPersonId": "ID",
    "importablefield.userPersonDni": "Document d'identitat",
    "importablefield.userUsername": "Usuari",
    "importablefield.userEmail": "Email",
    "importablefield.userPassword": "Clau",
    "importablefield.stage_type": "Tipus",
    "importablefield.responsible": "Responsable",
    "importablefield.dateEvent": "Data",
    "importablefield.slot.code": "Codi franja horària",
    "importablefield.slot.hourIni": "Hora inici",
    "importablefield.slot.hourEnd": "Hora fi",
    "importablefield.subject.name": "Matèria",
    "importablefield.group.name": "Nom grup",
    "importablefield.group.code": "Codi grup",
    "importablefield.teachers.name": "Professors",
    "importablefield.isClassroom": "És aula",
    "importablefield.isBookable": "Reservable",
    "importablefield.isMeetingRoom": "Reunions",
    "importablefield.subject.code": "Codi matèria",
    "importablefield.classGroup": "Grup Classe",
    "importablefield.documentNumber": "Número de document d’identitat",
    "importablefield.documentType": "Tipus de document d’identitat",
    "importablefield.residenceTown": "Localitat de residència",
    "importablefield.residenceProvince": "Província de residència",
    "importablefield.contactEmail": "Correu electrònic",
    "importablefield.childStreetNumber": "Número",
    "importablefield.childIdentifyCode": "Identificador de l’alumne/a",
    "importablefield.contactChildPhone": "Telèfon",
    "importablefield.childAddressName": "Nom via",
    "importablefield.childAddressType": "Tipus de via",
    "importablefield.childAddressBlock": "Bloc",
    "importablefield.childAddressPlant": "Planta",
    "importablefield.childAddressStair": "Escala",
    "importablefield.childAddressDoor": "Porta",
    "importablefield.childAddressInfo": "Resta de dades de l’adreça",
    "importablefield.childEsferaEmail": "Contacte alumne - Correu electrònic",
    "importablefield.childEsferaPhone": "Contacte alumne - Telèfon",
    "importablefield.childEsferaSurname1": "Primer Cognom",
    "importablefield.childEsferaSurname2": "Segon Cognom",
    "importablefield.tutor1Name": "Tutor 1 - nom",
    "importablefield.tutor2Name": "Tutor 2 - nom",
    "importablefield.tutor1Dni": "Tutor 1 - doc. identitat",
    "importablefield.tutor2Dni": "Tutor 2 - doc. identitat",
    "importablefield.tutor1Surname": "Tutor 1 - 1r cognom",
    "importablefield.tutor2Surname": "Tutor 2 - 1r cognom",
    "importablefield.group": "Grup classe",
    "importablefield.subjects": "Matèries",
    "importablefield.stage": "Nivell",
    "importablefield.course": "Curs",
    "importablefield.codeAmbit": "Codi àmbit",
    "importablefield.codeDimension": "Codi competència",
    "importablefield.nameDimension": "Nom competència",
    "importablefield.codiCompetence": "Codi criteri",
    "importablefield.nameCompetence": "Nom criteri",
    "importablefield.subject.area.name": "Departament",
    "importablefield.subject.parent": "Mòdul",
    "importablefield.subject.study": "Estudi",
    "importablefield.subject.color": "Color",
    "importablefield.enrolmentDate": "Data matricula",
    "importablefield.payerNif": "NIF pagador",
    "importablefield.payerIban": "IBAN",
    "importablefield.payerPayMethod": "Forma pagament",
    "importablefield.entryDate": "Data alta",
    "importablefield.fatherStreet": "Adreça pare",
    "importablefield.motherStreet": "Adreça mare",
    "importablefield.curriculum": "Curriculum",
    "importablefield.personFields": "Camps personalitzats",
    "importablefield.recoverToken": "Enllaç de recuperació",
    "importablefield.contacts": "Contactes",
    "importablefield.area.code": "Codi Departament",
    "importablefield.responsibleUser.person.dni": "DNI responsable",
    "importablefield.tutor1Surname2": "Tutor 1 - 2n cognom",
    "importablefield.tutor1DNI": "Tutor 1 - DNI",
    "importablefield.tutor1NIF": "Tutor 1 - NIF",
    "importablefield.tutor1Street": "Tutor 1 - Adreça",
    "importablefield.tutor1Contacts": "Tutor 1 - Contactes",
    "importablefield.tutor2Surname2": "Tutor 2 - 2n cognom",
    "importablefield.tutor2DNI": "Tutor 2 - DNI",
    "importablefield.tutor2NIF": "Tutor 2 - NIF",
    "importablefield.tutor2Street": "Tutor 2 - Adreça",
    "importablefield.tutor2Contacts": "Tutor 2 - Contactes",
    "importablefield.tags": "Etiquetes",
    "importablefield.street": "Direcció",
    "import.matching.personContactExist": "Persona amb el contacte",
    "import.matching.personContactNew": "Person  que s'importa",
    "importablefield.contact": "Contacte",
    "importablefield.password": "Contrasenya",
    "importablefield.evaluabletype_value": "Nota vinculada",
    "importablefield.identifier": "Identificador",
    "importablefield.courseStagesCodes": "Codis cursos",
    "importablefield.center_code": "Codi centre",
    "importablefield.year_code": "Codi any",
    "importablefield.program_id": "ID programació",
    "importablefield.program_description": "Descripció programació",
    // "importablefield.commerce_name": "Nom línia de negoci", --> already defined.
    // "importablefield.stage_name": "Nom nivell", --> already defined.
    "importablefield.coursestage_name": "Nom curs",
    "importablefield.subject_name": "Nom matèria",
    "importablefield.content_id": "ID",
    "importablefield.content_parent_id": "ID contingut pare",
    "importablefield.content_parent_title": "Títol contingut pare",
    "importablefield.content_type": "Tipus",
    "importablefield.content_title": "Títol",
    "importablefield.content_description": "Descripció",
    "importablefield.convocatory_code": "Codi convocatòria",
    "importablefield.competence_code": "Codi competència",
    "importablefield.competence_parent_code": "Codi competència pare",
}

import spacesApi from '@/api/core/spaces'

export const spaces = {
  state: {
    spaces: null,
  },
  mutations: {
    setSpaces(state, spaces){
      state.spaces = spaces;
    },
      resetCenterInfo(state) {
          spaces: null;
      },
  },
  actions: {
    saveSpace({commit, state}, params) {
      return spacesApi.saveSpace(params.space);
    },
    updateSpace({commit, state}, params) {
      return spacesApi.updateSpace(params.space);
    },
    deleteSpace({commit, state}, params) {
      return spacesApi.deleteSpace(params.space);
    },

  },
  getters: {
    getSpaces: state =>{
      return state.spaces;
    },
  }
}

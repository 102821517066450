import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import ParameterApi from '@/api/core/parameters';
import ClientApi from '@/api/core/client';

Vue.use(Vuex)
import modules from './modules';

export default new Vuex.Store({
    state: {
        breadcrumbs: [{name: '', link: 'root'}], // Llistat de elements del breadcrumb, on cada element te name i link
        type: null, // Tipo de breadcrumb, Usuari, Administracio, Home, serveix per mostrar la icona del principi
        pageTitle: null,
        pageSubtitle: null,
        error: null,
        unreadMessages: 0,
        listUnreadMessages: [],
        expandedMenuId: null,
        configMenuOpen: null,   // Indica si en el leftmenu, tenim el menu de configuracio obert o plegat
        paginator: 2,
        showCourseSelector: false,
        menuTypes: {
            USER: 1,
            ADMIN: 2,
            OTHER: 3
        },
        cancelToken: axios.CancelToken.source(),
        menuReload: 0, //Increases +1 every time a menu link is clicked and we are already in that route,
        // it is used to "refresh" a component when we click a route that we are already in it
        permissionsLoaded: false, //True when the app has finished loadingPage the permissions
        loadingStatus: false, //false by default. For showing the spinner must be true.
        selectedRows: 0,
        deviceSize: "xl",
        breadCrumbTitleIcon:{
            class: '',
            link: {},
            tooltip: '',
            newTab: false,
        },
        breadcrumbYear: undefined,
        headerState: {
            state: false,
            partially: false,
        },
        client: {
            code: '',
            logo: '',
        },
        selectedRowsData: [],
    },
    mutations: {
        addBreadcrumb(state, b) {
            if (state.breadcrumbs[state.breadcrumbs.length - 1].name.localeCompare(b.name) !== 0) {
                let last = state.breadcrumbs[state.breadcrumbs.length - 1].position
                if (last == undefined || last == NaN) last = 0
                b.position = last + 1
                state.breadcrumbs.push(b)
            }
        },
        resetBreadcrumb(state, t) {
            state.type = t
            state.breadcrumbs = [{name: '', link: 'root'}]
            state.showCourseSelector = false
        },
        setPageTitles(state, titles) {
            state.pageTitle = titles.title
            state.pageSubtitle = titles.subtitle
        },
        setUnreadMessages(state, n) {
            state.unreadMessages = n
        },
        setListUnreadMessages(state, n) {
            state.listUnreadMessages = n
        },
        setError(state, error) {
            // console.log('Error rebut: ' + error)
            state.error = error
        },
        setExpandedMenuId(state, id) {
            state.expandedMenuId = id
            //console.log("expandedMenuId", id);
            sessionStorage.setItem('expandedMenuId', id)
        },
        setConfigMenuOpen(state, isOpen) {
            state.configMenuOpen = isOpen
            sessionStorage.setItem('configMenuOpen', isOpen)
        },
        clearError(state) {
            //console.log("clearing error");
            state.error = null
        },
        /* Set the number of pages to be displayed by the paginator */
        paginator(state, m) {
            if (m.paginator == 'full') {
                state.paginator = 2
            } else {
                state.paginator = 1
            }
        },
        splice(state, from) {
            state.breadcrumbs.splice(from, state.breadcrumbs.length)
        },
        spliceToName(state, to) {
            state.breadcrumbs.forEach(function (elem) {
                    if (elem.link == to && elem.link != undefined) {
                        state.breadcrumbs.splice(elem.position, state.breadcrumbs.length)
                        return
                    }
                }
            )
        },
        setShowCourseSelector(state, show) {
            state.showCourseSelector = show
        },
        setMenuReload(state, value) {
            state.menuReload = value
        },
        setPermissionsLoaded(state, value) {
            state.permissionsLoaded = value
        },
        setLoading(state, value) {
            state.loadingStatus = value;
        },
        setSelectedRows(state, value) {
            state.selectedRows = value;
        },
        setHeaderState(state, value) {
            Vue.set(state.headerState, value.ref, {state: value.state, partially: value.partially});
        },
        setDeviceSize(state, value){
            state.deviceSize = value;
        },
        setBreadcrumbYear(state, value){
            state.breadcrumbYear = value;
        },
        setBreadcrumbTitleIcon(state, icon) {
            state.breadCrumbTitleIcon = {
                class: icon.class,
                link: icon.link,
                tooltip: icon.tooltip,
                newTab: icon.newTab,
            };

        },
        setParametersStudies(state, parameters){
            parameters.forEach(function (parameter) {
                state.parametersStudies[parameter.name] = parameter.value;
            })
        },
        setClientCode(state, value){
            state.client.code = value;
        },
        setClientLogo(state, value){
            state.client.logo = value;
        },
        setSelectedRowsData(state, value){
            state.selectedRowsData = value;
        }
    },
    getters: {
        getClientCode: state=>{
          return state.client.code;
        },
        getExpandedMenuId: state => {
            if (state.expandedMenuId === null) {
                return sessionStorage.getItem('expandedMenuId')
            }
            return state.expandedMenuId
        },
        getConfigMenuOpen: state => {
            if (state.configMenuOpen === null) {
                return sessionStorage.getItem('configMenuOpen')
            }
            return state.configMenuOpen
        },
        getError: state => {
            return state.error
        },
        getMenuState: state => {
            return state.paginator
        },
        getMenuReload: state => {
            return state.menuReload
        },
        getPermissionsLoaded: state => {
            return state.permissionsLoaded
        },
        isLoading: state => {
            return state.loadingStatus;
        },
        getSelectedRows: state => {
            return state.selectedRows;
        },
        getDeviceSize: state => {
            return state.deviceSize;
        },
        getBreadcrumbYear: state => {
            return state.breadcrumbYear;
        },
        getClient: state => {
            return state.client;
        },
        getSelectedRowsData: state => {
            return state.selectedRowsData;
        },
    },
    modules,
    actions: {
        resetCenterInfo({commit}) {
            commit('resetCenterInfo');
        },
        startLoading({ commit }) {
            commit("setLoading", true)
        },
        stopLoading({ commit }) {
            commit("setLoading", false)
        },
        getWindowDeviceSize({ state,commit }, params){
            let window = params.window;
            let windowWidth = window.width();
            let size = "xl";
            if(windowWidth >= 1200){
                size = "xl";
            }
            else if(windowWidth >= 992){
                size = "lg";
            }
            else if(windowWidth >= 768){
                size = "md";
            }
            else if(windowWidth > 575){
                size = "sm";
            }
            else{
                size = "xs";
            }
            // console.log("console resize: ",windowWidth," - ",size);
            if(state.deviceSize !== size){
                commit("setDeviceSize", size);
            }
        },
        getClientFromUrl({ commit }){
            let codiClientIni = document.domain.indexOf("://") + 1;
            let codiClientFi = document.domain.indexOf(".");
            let code = document.domain.substring(codiClientIni, codiClientFi);
            commit("setClientCode", code);
        },
        getClientImage({ state, commit }){
            ClientApi.clientImage(state.client.code).then(response  => {
                commit("setClientLogo", response);
            });
        }
    },
})
